// React/Redux
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, map, catchError } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax';

// C-Core
import fileDuck from './fileDuck'
import { updateFiles } from '../api/files'
import authDuck from './authDuck'


const notification = {
  status: 'error',
  message: 'Could not update file'
}

const fileEpic = action$ => action$.pipe(

  ofType(fileDuck.actions.setUpdating),
  mergeMap((action$, state$) =>
    
      // TODO: Remove the localStorage call and use Redux selector - Move as below
      // TODO: Use an axios style interceptor or global/instance default for headers.
      ajax.put(`${updateFiles}/${action$.payload.id}`,{...action$.payload},
        {
          'Authorization': `Bearer ${localStorage.getItem('rpas-token')}`,
          'Content-Type': 'application/json'
        },
      ).pipe(
        map(response => {
          return fileDuck.actions.fileSuccess({message: 'Update successful'})
        }),
        catchError(error => {
          if (error.status === 401) {
            return of(authDuck.actions.logout({message: 'Your session has expired, please sign in again'}))
          }
          return of(fileDuck.actions.fileError({...notification, message: 'An application error occurred.', error}))
        })
      )
  ),
  catchError(error => of(fileDuck.actions.fileError(error)))
  )



export default fileEpic
