import React, { useContext } from 'react'
import { ReactReduxContext } from '@appbaseio/reactivesearch/lib/utils'
import PolygonLayer from '../components/PolygonLayer'

function SearchLayersContainer () {
  const reactiveContext = useContext(ReactReduxContext)
  const hits = reactiveContext.storeState.hits.SearchResult
    ? reactiveContext.storeState.hits.SearchResult.hits
    : []

  const items = hits.map(hit => {
    const item = { ...hit._source, _id: hit._id }
    return item
  })
  
  return (
    <>
      {items &&
        items.map(item => {
          return <PolygonLayer item={item} id={item._id} key={item._id} />
        })}
    </>
  )
}

export default SearchLayersContainer
