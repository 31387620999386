import jwt_decode from 'jwt-decode'

import { createSelector } from '@reduxjs/toolkit'

const auth = state => state.auth

export const authError = createSelector([auth], auth => {
    return auth.errorMessage
})

export const getToken = createSelector([auth], auth => {
    return auth.token
})

export const userId = createSelector([auth], auth => {
    return auth.user_id
})

export const isLoading = createSelector([auth], auth => {
    return auth.loading
})

export const isAuthenticated = createSelector([auth], auth => {
    
    // Does it exist
    if (!auth.token) return false

    // Is it valid
    const decoded = jwt_decode(auth.token)
    const now = new Date()
    if (decoded.exp * 1000 < now.getTime()) return false

    // TODO:
    // Token exists and hasn't expired.
    // This token is not validated, api call required for that.
    // All requests to api use this token so get/put/delete requests will validate this.
    // This is a short term quick implementation prior to httpOnly cookies being used.
    return true
})