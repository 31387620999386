import { useSelector } from 'react-redux'
import { isAuthenticated } from '../redux/authSelector'

import { Flex, Box, Image } from '@chakra-ui/react'
import LoginContainer from '../containers/LoginContainer'
import bgImage from '../images/CCORE _logo_1x.png'
import logoImage from '../images/CCORE _logo_1x.png'

const Auth = ({children}) => {
	const auth = useSelector(isAuthenticated)

	if (!auth) {
		return ( 
			<Flex 
				width="Full" 
				align="center" 
				height="100vh"
				justifyContent="center" 
				bgImage={bgImage}
				bgPosition="bottom"
				bgRepeat="no-repeat"
				>
				<Box 
					p={8} 
					maxWidth="500px" 
					borderWidth={1} 
					borderRadius={8} 
					boxShadow="lg"
					>
					<Box textAlign="center">
						<Image src={logoImage}/>
					</Box>
					<LoginContainer />
				</Box>
			</Flex>
		)
	}

    return <>{children}</>
}

export default Auth