import { createSelector } from '@reduxjs/toolkit'

export const allProjects = state => state.projects
export const filterValue = state => state.projects.filterValue

export const getFilteredProjects = createSelector(
    [allProjects, filterValue],
    (projects, filterVal) => {
        if (!projects || projects.length === 0) {
            return []
        }
        return projects
    }
)

export const getProjects = createSelector([allProjects], projects => {
    return projects.projects
})

export const storageUtilisation = createSelector([allProjects], projects => {
    if (projects && projects.length > 0) {
        const reducer = (accumulator, value) => accumulator + value.Size
        return projects.reduce(reducer, 0)
    }
    return 0
})
