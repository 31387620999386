import { useState } from 'react'
import { Input, InputGroup, InputRightElement, IconButton, Flex } from '@chakra-ui/react'
import { CheckIcon, EditIcon, CloseIcon } from '@chakra-ui/icons'

const EditableItem = ({
    content='',
    handleSave=console.log
}) => {
    const [edit, setEdit] = useState(false)
    const [value, setValue] = useState(content)
    const [visible, setVisible] = useState(false)
    const handleChange = (event) => setValue(event.target.value)
    const handleClick = () => {
        if (edit) handleSave(value)
        setEdit(!edit)
    }

    const handleUndo = () => {
        setValue(content)
        setEdit(false)
    }

    return (
        <Flex>
        <InputGroup onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
            <Input value={value} variant={edit ? "outline" : "unstyled"} onChange={handleChange} isReadOnly={!edit}/>
            <InputRightElement visibility={visible ? "visible" :"hidden"}>
                <IconButton mb={edit ? "0" : "4"} onClick={handleClick} size="sm" variant="ghost" icon={edit ? <CheckIcon color="green.500" /> : <EditIcon />}></IconButton>
            </InputRightElement>
        </InputGroup>
        <IconButton visibility={edit ? "visible" :"hidden"} onClick={handleUndo} size="xs" variant="ghost" icon={<CloseIcon color="gray.500" />} mt={2}/>
        </Flex>
    )
}

export default EditableItem