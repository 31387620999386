import { GridItem, Flex, Box } from '@chakra-ui/react'

/**
 * Component for selecting different context
 */
const LeftMenu = ({
    h = 'calc(100vh)',
    bg = 'blue.500',
    display = { base: 'none', md: 'block' },
    children,
    toolbox,
}) => {
    return (
        <GridItem h={h} bg={bg} display={display}>
            <Flex direction='column'>
                {children}
                <Box position='fixed' bottom='0'>
                    {toolbox}
                </Box>
            </Flex>
        </GridItem>
    )
}

export default LeftMenu
