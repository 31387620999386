// Appbase
import { ReactiveList } from '@appbaseio/reactivesearch'

// React
import React, { useContext } from 'react'
import { ReactReduxContext } from '@appbaseio/reactivesearch/lib/utils'
import { useSelector, useDispatch } from 'react-redux'
import useScroll from '../hooks/useScroll'

// C-CORE
import FileItem from '../components/FileItem'
import MapContainer from './MapContainer'
import SearchLayersContainer from './SearchLayersContainer'
import mapDuck from '../redux/mapDuck'
import { getSelectedItem } from '../redux/mapSelector'
import RasterTileContainer from './RasterTileContainer'

// Chakra
import { Flex, Box, IconButton, Icon, Tooltip, VStack, StackDivider, Divider } from '@chakra-ui/react'
import { GrMap } from 'react-icons/gr'
import { MdZoomOutMap } from 'react-icons/md'
import { featureCollection } from '@turf/helpers'
import bbox from '@turf/bbox'

function convertToBBox (bbox) {
      // WSEN bbox
      const dbbox = bbox.coordinates[0]
      return [ dbbox[2][0], dbbox[0][1], dbbox[1][0], dbbox[2][1] ]
}

const ResultItemMapBtn = (props) => {
    const [ref, executeScroll] = useScroll()
    const selectedItem = useSelector(getSelectedItem)
    const dispatch = useDispatch()

    const zoomTo = () => {
        const bbox = convertToBBox(props.data.bbox)
        dispatch(mapDuck.actions.fitBounds(bbox))
    }

    const handleSelect = () => {
        zoomTo(props.data)
        dispatch(mapDuck.actions.setHighlightedMapItem(props.data))
    }
    
    const highlightItem = selectedItem && selectedItem.id === props.data.id

    if (highlightItem && ref && ref.current) {
        executeScroll()
    }

    return (
        <IconButton onClick={handleSelect} icon={<Icon as={GrMap} />} variant="ghost" colorScheme="blue" size="lg" />
    )
}

const SearchResultsContainer = () => {
    const dispatch = useDispatch()
    const reactiveContext = useContext(ReactReduxContext)
    const hits = reactiveContext.storeState.hits.SearchResult
        ? reactiveContext.storeState.hits.SearchResult.hits
        : []

    const items = hits.map(hit => {
        return { geometry: hit._source.bbox }
    })
    
    const resultBounds = () => {
        const fc = featureCollection(items)
        return bbox(fc)
    }

    const zoomToResults = () => {
        dispatch(mapDuck.actions.fitBounds(resultBounds()))
    }

    const showData = (uri, bb) => {
        dispatch(mapDuck.actions.setRasterPreview(uri))

        const bbox = convertToBBox(bb)
        dispatch(mapDuck.actions.fitBounds(bbox))
    }

    return (
        <Flex>
            <Box flexGrow={1} pr={5}>
                <Box width="100%" align="right">
                    <Tooltip label="Zoom to all results">
                        <IconButton icon={<Icon as={MdZoomOutMap} />} size="sm" onClick={zoomToResults} />
                    </Tooltip>
                </Box>
                <Box 
                    height="calc(100vh - 185px)" 
                    overflow="scroll" 
                    flex={1} 
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        'scrollbarWidth': 'none'
                    }}
                >
                    <VStack
                        divider={<StackDivider borderColor='gray.200' />}
                        spacing={4}
                        align='stretch'
                    >
                        <ReactiveList
                            dataField="title"
                            componentId="SearchResult"
                            react={{
                                and: ['SearchSensor'],
                            }}
                            stream={true}
                            // render={ ({loading, error, data, streamData}) => {
                            //     console.log(streamData)
                            //     return (
                            //         <>
                            //         <ul>
                            //             {streamData.map(item => <li style={"color: red"}>{item.properties.id}</li>)}
                            //         </ul>
                            //         <ul>
                            //             {data.map(item => <li>{item.properties.title}</li>)}
                            //         </ul>
                            //         </>
                            //     )
                            // }}
                            renderItem={result => { 
                                const file = result.properties
                                const isCog = file.cog
                                return (
                                    <Box key={result.id} >
                                    <FileItem file={file}>
                                        {isCog 
                                            ? <IconButton icon={<Icon as={GrMap} />} colorScheme="blue" variant="ghost" size="lg" onClick={() => showData(`s3://ccore-private-upload/${file.storage_key}`, result.bbox)}/>
                                            : <ResultItemMapBtn data={result} />
                                        }
                                    </FileItem>
                                    <Divider m={3} />
                                    </Box>
                                )
                            }
                        }
                        onData={({data}) => {
                            if (data.length > 0) {
                                zoomToResults()
                            }
                        }}
                        />
                    </VStack>
                </Box>
            </Box>
            <Box w={{base: '100%', md: "300px", lg: "500px"}} height="calc(100vh - 150px)">
                <MapContainer>
                    <RasterTileContainer />
                    <SearchLayersContainer />
                </MapContainer>
            </Box>
        </Flex>
    )
}

export default SearchResultsContainer
