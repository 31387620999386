import React, { useState, useEffect } from 'react'
import RasterLayer from '../components/RasterLayer'
import httpToHttps from '../utils/httpToHttps'
import {  useDispatch, useSelector } from 'react-redux'
import { getUri } from '../redux/mapSelector'
import mapDuck from '../redux/mapDuck'

function RasterTileContainer () {
    const uri = useSelector(getUri)
    const dispatch = useDispatch()
    const [tileJson, setTileJson] = useState({})

    useEffect(() => {
        console.log("URI: ", uri)
        if (uri) {
            async function fetchTileJson () {
                const tileJsonResponse = await fetch(
                    `https://titiler.c-core.app/cog/tilejson.json?tile_scale=1&TileMatrixSetId=WebMercatorQuad&url=${uri}`
                )
                const tileJson = await tileJsonResponse.json()
                const { center, minzoom } = tileJson
                const [longitude, latitude] = center
                const viewport = { latitude, longitude, zoom: minzoom }
                dispatch(mapDuck.actions.changeViewport(viewport))

                setTileJson(tileJson)
            }
            fetchTileJson()
        }
    }, [setTileJson, uri, dispatch])
    
    const { tiles } = tileJson
    const httpsTiles = tiles ? tiles.map(tile => httpToHttps(tile)) : []

    return (
        <>
            {tileJson.center && <RasterLayer
                id='raster_preview'
                {...tileJson}
                tiles={httpsTiles}
            />}
        </>
    )
}

export default RasterTileContainer
