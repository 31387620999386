import { useDispatch, useSelector } from 'react-redux'
import { userId } from'../redux/authSelector'
import fileDuck from '../redux/fileDuck'
import Keyword from '../components/Keyword'
import { Box } from '@chakra-ui/react'

const KeywordContainer = ({file}) => {

    const user = useSelector(userId)
    const dispatch = useDispatch()
    const handleEdit = (payload) => {
        let data = {
            ...file,
            user,
            keywords: payload.split(",")
        }
        dispatch(fileDuck.actions.setUpdating(data))
    }

    return (
        <Box>
            {file?.keywords && <Keyword content={file.keywords} handleSave={handleEdit}/>}
        </Box>
    )
}

export default KeywordContainer