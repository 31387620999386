// import axios from 'axios'

import { baseUri } from '../config'

export const getFiles = `${baseUri}files`

export const deleteFile = `${baseUri}files`

export const updateFiles = `${baseUri}files`


// Potential to use Axios here and group all api calls together...
// Not sure how this would work with observables in Epics.

// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('rpas-token')}`
// export const getFilesAxios = () => axios.get(`${baseUri}files`)
// export const updateFiles = (uuid) => axios.put(`${baseUri}files/${uuid}`)
