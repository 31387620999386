import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    uploading: false,
    uploadHistory: [],
    error: false,
    errorMessage: null,
}

function uploadFile (state, action) {
    // const { payload } = action
    // const history = state.uploadHistory
    // history.push({name: payload.name, size: payload.size})
    return {
        ...state,
        uploadHistory : ''

    }
}

function uploadError (state, action) {
    const { payload } = action
    return {
        ...state,
        errorMessage: payload,
        error: true
    }
}   

function uploadSuccess (state, action) {
    return state
}

const upload = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        uploadFile,
        uploadError,
        uploadSuccess
    },
})

export default upload
