import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { combineEpics, createEpicMiddleware } from 'redux-observable'

import fileDuck from './fileDuck'
import fileEpic from './fileEpic'
import fileUpdateEpic from './fileUpdateEpic'
import fileDeleteEpic from './fileDeleteEpic'

import projectDuck from './projectDuck'
import projectEpic from './projectEpic'

import authDuck from './authDuck'
import authEpic from './authEpic'

import uploadDuck from './uploadDuck'
import uploadEpic from './uploadEpic'

import controlsDuck from './controlsDuck'

import mapDuck from './mapDuck'

import notificationEpic from './notificationEpic'

const rootEpic = (...args) => combineEpics(
    fileEpic,
    fileUpdateEpic,
    fileDeleteEpic,
    authEpic,
    projectEpic,
    uploadEpic,
    notificationEpic
    )(...args)

const epicMiddleware = createEpicMiddleware()

const reducer = combineReducers({
    files: fileDuck.reducer,
    projects: projectDuck.reducer,
    auth: authDuck.reducer,
    upload: uploadDuck.reducer,
    controls: controlsDuck.reducer,
    map: mapDuck.reducer
})

const store = configureStore({
    reducer: reducer,
    middleware: [epicMiddleware],
    devTools: true,
})

epicMiddleware.run(rootEpic)
window.store = store

export default store
