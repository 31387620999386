// React
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { VStack, StackDivider, Box, Text } from '@chakra-ui/react'

import projectDuck from '../redux/projectDuck'
import { getProjects } from '../redux/projectSelector'
import ProjectItem from '../components/ProjectItem'

// Chakra

const ListProjectsContainer = () => {
    const dispatch = useDispatch()
    const projects = useSelector(getProjects)

    useEffect(() => {
        dispatch(projectDuck.actions.getProjects())
    }, [dispatch])

    return (
        // List Items.
        <Box p='15px'>
            <Text>Projects</Text>
            <VStack
                divider={<StackDivider borderColor='gray.200' />}
                spacing={4}
                align='stretch'
            >   
                {projects && Object.keys(projects).length > 0 && Object.keys(projects).map((v, i) => <ProjectItem project={projects[i]} key={i} />)}
            </VStack>
        </Box>
    )
}

export default ListProjectsContainer
