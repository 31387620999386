import { createSelector } from '@reduxjs/toolkit'

export const allFiles = state => state.files
export const filterValue = state => state.files.filterValue

export const getFilteredFiles = createSelector(
    [allFiles, filterValue],
    (files, filterVal) => {
        if (!files.files || files.files.length === 0) {
            return {}
        }
        return files.files
    }
)

export const getFiles = createSelector([allFiles], files => {
    return files.files
})

export const storageUtilisation = createSelector([allFiles], files => {
    const data =  Object.values(files.files)
    
    if (data) {  
        const reducer = (accumulator, value) => accumulator + value.size
        return data.reduce(reducer, 0)
    }
    return 0
})
