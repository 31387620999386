import { Layer, Source } from 'react-map-gl'
import React from 'react'

function PolygonLayer ({
  item,
  id,
  fillColor = 'blue',
  fillOpacity = .5,
  type = 'geojson'
}) {
  const paint = {
    'fill-color': fillColor,
    'fill-opacity': fillOpacity,
  }

  return (
    <Source type={type} data={item} id={id}>
      <Layer source={id} type={'fill'} paint={paint} />
    </Source>
  )
}

export default PolygonLayer
