import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    filterValue: '',
    projects: [],
    error: false,
    errorMessage: null,
}

function getProjects (state, action) {
    return { ...state, loading: true }
}

function setProjectFilter (state, action) {
    const { payload } = action
    return { ...state, filterValue: payload }
}

function projectSuccess (state, action) {
    return {
        ...state
    }
}

function getProjectSuccess (state, action) {
    const { payload } = action
    return {
        ...state,
        projects: payload,
        loading: false,
        error: false,
        errorMessage: null,
    }
}

function projectError (state, action) {
    const { payload } = action
    return {
        ...state,
        error: true,
        loading: false,
        errorMessage: payload.message,
    }
}

const projects = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        getProjects,
        projectSuccess,
        getProjectSuccess,
        projectError,
        setProjectFilter
    },
})

export default projects
