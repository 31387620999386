import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, map, catchError } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax';
import projectDuck from './projectDuck'
import { getProjects } from '../api/projects'

import authDuck from './authDuck'


const notification = {
  status: 'error',
  message: 'Could not fetch projects.'
}

const projectEpic = action$ => action$.pipe(

  ofType(projectDuck.actions.getProjects),
        mergeMap((action, state$) =>
            ajax({
              url: getProjects,
              method: 'GET',
              headers: {
                Authorization: `Bearer ${localStorage.getItem('rpas-token')}`
              }
            }).pipe(
              map(response => {
                return projectDuck.actions.getProjectSuccess(response.response)
              }),
              catchError(error => {
                if (error.status === 401) {
                  return of(authDuck.actions.logout({message: 'Your session has expired, please sign in again'}))
                }
                return of(projectDuck.actions.projectError({...notification, message: 'An application error occurred.', error}))
              })
            )
        )
    )

export default projectEpic
