import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, map, catchError } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax';

import uploadDuck from './uploadDuck'
import { getFiles } from '../api/files'
import fileDuck from './fileDuck'
import authDuck from './authDuck'

const notification = {
  status: 'error',
  message: 'Could not fetch file list'
}

const fileEpic = action$ => action$.pipe(

  ofType(uploadDuck.actions.uploadFile),

  mergeMap((action$, state$) =>
      // TODO: Remove the localStorage call and use Redux selector - Move as below
      // TODO: Use an axios style interceptor or global/instance default for headers.
      ajax.post(`${getFiles}/`,{
          user: action$.payload.user,
          storage_key: action$.payload.name,
          description: 'please add a description',
          keywords: ["Add", "keywords"],
          size: action$.payload.size,
          file_label: action$.payload.name,
          cog: 'processing'
        },
        {
          'Authorization': `Bearer ${localStorage.getItem('rpas-token')}`,
          'Content-Type': 'application/json'
        },
      ).pipe(
        map(response => {
          return fileDuck.actions.getFiles()
        }),
        catchError(error => {
          if (error.status === 401) {
            return of(authDuck.actions.logout({message: 'Your session has expired, please sign in again'}))
          }
          return of(fileDuck.actions.fileError({...notification, message: 'An application error occurred.', error}))
        })
      )
  ),
  catchError(error => of(uploadDuck.actions.uploadError(error)))
)

export default fileEpic