import EditableItem from '../components/EditableItem'
import { useDispatch, useSelector } from 'react-redux'
import fileDuck from '../redux/fileDuck'
import { userId } from'../redux/authSelector'

const EditItemContainer = ({file, name}) => {

    const user = useSelector(userId)
    const dispatch = useDispatch()
    const handleEdit = (payload) => {
        let data = {
            ...file,
            user
        }
        data[name] = payload
        dispatch(fileDuck.actions.setUpdating(data))
    }
    return <EditableItem content={file[name]} handleSave={handleEdit} />
}

export default EditItemContainer