import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Button, 
    CircularProgress 
} from '@chakra-ui/react';

import ErrorMessage from './ErrorMessage';
    
const Login = ({
    handleSubmit = console.log,
    setEmail = console.log,
    setPassword = console.log,
    isLoading = false,
    error,
}) => {
    return (
        <Flex width="Full" align="center" justifyContent="center">
            <Box p={8}>
                <Box my={4} textAlign="left" w={{sm: "100%", md: "400px"}}>
                    <form onSubmit={handleSubmit}>
                        {error && <ErrorMessage message={error} />}
                        <FormControl isRequired>
                            <FormLabel>Email</FormLabel>
                            <Input type="email" 
                                placeholder="test@test.com"
                                size="lg"
                                onChange = {event => setEmail(event.currentTarget.value)}
                                autoComplete="username"
                            />
                        </FormControl>
                        <FormControl isRequired mt={6}>
                            <FormLabel>Password</FormLabel>
                            <Input type="password" 
                                placeholder="********"
                                autoComplete="current-password"
                                size="lg"
                                onChange={event => setPassword(event.currentTarget.value)}
                            />
                        </FormControl>
                        <Button variant="outline" width="full" mt={4} type="submit">
                            {isLoading 
                                ? <CircularProgress isIndeterminate size="24px" color="blue" />
                                : 'Sign In'
                            }
                        </Button>
                    </form>
                </Box>
            </Box>
        </Flex>
    )
}

export default Login