import MapContainer from '../containers/MapContainer'
import SearchLayersContainer from '../containers/SearchLayersContainer'

// TODO: Seperate the map component viewport from the one on the search page

const Map = () => {
    return (
        <MapContainer>
            <SearchLayersContainer />
        </MapContainer>
    )
}
 export default Map