import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    updating: false,
    filterValue: '',
    files: [],
    error: false,
    errorMessage: null,
}

function setFileFilter (state, action) {
    const { payload } = action
    return { ...state, filterValue: payload }
}


/**
 * Get
 * 
 */

function getFiles (state, action) {
    return { ...state, loading: true }
}

function getFileSuccess (state, action) {
    const { payload } = action
    return {
        ...state,
        files: payload,
        loading: false,
        error: false,
        errorMessage: null,
    }
}

function getFileError (state, action) {
    const { payload } = action
    return {
        ...state,
        error: true,
        loading: false,
        errorMessage: payload.message,
    }
}



/** 
 * Update 
 * 
 * */

function fileSuccess (state, action) {
    // TODO
    return state
}

function fileError (state, action) {
    // TODO
    return state
}

/** 
 * Delete
 * 
 */

function deleteFile (state, action) {
    // const { payload } = action
    return {
        ...state
    }
}

/**
 * Status
 * TODO: Use this for all CRUD operations
 */

function setUpdating (state, action) {
    return {
        ...state,
        updating: true
    }
}

const files = createSlice({
    name: 'files',
    initialState,
    reducers: {
        getFiles,
        getFileSuccess,
        getFileError,
        setUpdating,
        setFileFilter,
        fileSuccess,
        fileError,
        deleteFile
    },
})

export default files
