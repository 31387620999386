
// const customTheme = {
//     nav: {
//         baseStyle: {
//             backgroundColor: "rgba(255, 255, 255, 0.3)",
//             backdropFilter: "blur(10px) saturate(100%) contrast(45%) brightness(130%)",
//             borderRadius: "0px"
//         }
        
//     },
// }

const theme = {
    colors: {
        // This is just a test value..
        ccore: {
            100: 'green'
        }
    },

    components: {
        Tabs: {
                variants: {
                    line: {
                            borderBottom: "5px solid red",
                            paddingTop: '200px',
                            marginTop: '200px'
                    }
                }
        }

    }
}

export default theme