import {
    Flex,
    Center,
    Text,
    Spacer,
    Box,
    Heading,
    // IconButton,
    // Tooltip
} from '@chakra-ui/react'

// import { MdBuild } from 'react-icons/md'
import EditItemContainer from '../containers/EditItemContainer'
import KeywordContainer from '../containers/KeywordContainer'
// import { Link as ReactLink } from 'react-router-dom'
import DeleteFileBtnContainer from '../containers/DeleteFileBtnContainer'
import epochToDate from '../utils/epochToDate'

const FileItem = ({file, children}) => {

    return (
        <Flex>
            <Box>{/* Thumbnail here */}</Box>
            <Flex
                flexDirection='column'
                p='0px'
                flex={2}
            >
                <Heading fontSize={{ base: 'sm', md: 'lg' }}>
                    <EditItemContainer name="file_label" file={file}/>
                </Heading>
                <Spacer />
                <Text fontSize='xs'>{epochToDate(file.created_at)}</Text>
            </Flex>
            <Box
                p='5px'
                flex={2}
                display={{ base: 'none', md: 'block' }}
            >
                <Heading fontSize='sm'>Description:</Heading>
                <EditItemContainer name="description" file={file}/>
            </Box>
            <Box marginLeft='15px' width="200px">
                <KeywordContainer file={file}/>
            </Box>
            {/* <Spacer /> */}
            <Center>
                {children}
                {/* <Tooltip label="Manage File"> */}
                <DeleteFileBtnContainer file={file} variant="ghost" ml={2}/>
                {/* <IconButton
                    ml={2}
                    colorScheme='blue'
                    variant='outline'
                    size='sm'
                    icon={<MdBuild />}
                    as={ReactLink} to={`/file/${file.id}`} 
                /> */}
                {/* </Tooltip> */}
            </Center>
        </Flex>
    )
}

export default FileItem