import LeftMenu from '../components/LeftMenu'
import SettingsMenuItem from '../components/SettingsMenuItem'
import ListDataMenuItem from '../components/ListDataMenuItem'
// import MapDataMenuItem from '../components/MapDataMenuItem'
// import UploadMenuItem from '../components/UploadMenuItem'

const LeftMenuContainer = () => {
    return (
        <LeftMenu toolbox={<SettingsMenuItem />}>
            <ListDataMenuItem />
            {/* <UploadMenuItem /> */}
            {/* <MapDataMenuItem /> */}
        </LeftMenu>
    )
}

export default LeftMenuContainer
