// React / Redux
import defaultStore from './redux/store'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import Auth from './RouteComponents/Auth'
import { ReactiveBase } from '@appbaseio/reactivesearch'
import { es_url, es_key, es_index, es_type } from './config'

// Chakra
import { ChakraProvider, Grid, GridItem, Text, extendTheme } from '@chakra-ui/react'

// C-CORE
import HeaderContainer from './containers/HeaderContainer'
import LeftMenuContainer from './containers/LeftMenuContainer'
import Home from './RouteComponents/Home'
import FileDetails from './RouteComponents/FileDetails'
import Map from './RouteComponents/Map'

// Theme
import theme from './theme'

import 'mapbox-gl/dist/mapbox-gl.css';

const customTheme = extendTheme({
	theme
})

function App() {

	// auth duck
	// get/set token

	return (
		<ChakraProvider resetCSS theme={customTheme}>
			<Provider store={defaultStore}>
				<Router>
					<Auth>
					<ReactiveBase
						url={es_url}
						app={es_index}
						credentials={es_key}
						type={es_type}
						// theme={theme}
						enableAppbase={true}
						>
						<Grid h="100%" w="100%" p={0} templateColumns={{base: "auto", md: "60px auto"}} overflow="hidden">
							<LeftMenuContainer />
							<GridItem w="100%" h="100%">
								
								{/* Header Container */}
								<HeaderContainer />

								{/* Content Area */}
								<Switch>
									{/* <Route path="/" component={View} exact /> */}
									{/* <Route path="/login" component={login} /> */}
									<Route path="/" component={Home} exact />
									<Route path="/file/:id" component={FileDetails} />
									<Route path="/browse-data-map" component={Map} />
									<Route path="*">
										<Text>404 not found component to go here</Text>
										{/* TODO: not found error component */}
									</Route>
								</Switch>
							</GridItem>
						</Grid>
					</ReactiveBase>	
					</Auth>
				</Router>
			</Provider>
		</ChakraProvider>
	)
}

export default App
