// import axios from "axios"
import { baseUri } from '../config'

export const auth = {
    login() {
        // return axios.post(`${baseUri}auth/`, data, {withCredentials: true })
        return `${baseUri}auth/`
    },

    logout() {

    },

    refreshToken() {

    },

    checkAuth() {

    },

    recoverPassword() {

    },


}