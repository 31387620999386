// React
import { useDispatch } from 'react-redux'

// Chakra
import { DeleteIcon } from '@chakra-ui/icons'

// C-CORE
import fileDuck from '../redux/fileDuck'
import ConfirmationDialogue from "../components/ConfirmationDialogue";

const DeleteFileBtnContainer = ({file, mr = 0, ml = 0}) => {
    const dispatch = useDispatch()
    const handleDelete = () => {
        dispatch(fileDuck.actions.deleteFile(file.id))
    }

    return (
        <ConfirmationDialogue
            tooltipLabel="Delete this file"
            mr={mr}
            ml={ml}
            handleClose={handleDelete}
            iconComponent={<DeleteIcon />}
            btnVariant='ghost'
            btnIsRound={false}
            btnColor='blue'
            btnSize="sm"
            titleText='Delete File Confirmation'
            bodyText = 'Are you sure you want to delete this File? This action cannot be undone.'
            confirmText = 'Delete File'
            cancelText = 'Cancel'
        />
    )
}

export default DeleteFileBtnContainer