import {
    Flex,
    Center,
    Text,
    Spacer,
    Box,
    Heading,
    Button,
    Badge,
} from '@chakra-ui/react'
import { MdBuild } from 'react-icons/md'

const ProjectItem = ({
    name,
    id,
    created_at,
    description= 'Please enter a description for the project',
    maps = null,
    keywords= ['Please', 'Add', 'Keywords'],
    children,
}) => {
    return (
        <Flex>
            <Box>{/* Thumbnail here */}</Box>
            <Flex
                flexDirection='column'
                p='0px'
                width={{ base: '30%', md: '25%' }}
            >
                <Heading fontSize={{ base: 'sm', md: 'xl' }}>{id}</Heading>
                <Spacer />
                <Text fontSize='xs'>{created_at}</Text>
            </Flex>
            <Box
                p='5px'
                width={{ base: 'auto', md: '30%' }}
                display={{ base: 'none', md: 'block' }}
            >
                <Heading fontSize='sm'>Description:</Heading>
                <Text fontSize={{ base: 'sm' }}>
                    {description}
                </Text>
            </Box>
            <Box marginLeft='15px' width={{ base: '15%' }}>
                <Heading fontSize='xs'>Keywords:</Heading>
                <Flex pt='4px'>
                    <Badge mr='3px' colorScheme='blue'>
                        Tag1
                    </Badge>
                    <Badge colorScheme='blue'>Tag2</Badge>
                </Flex>
            </Box>
            <Spacer />
            <Center>{children}</Center>
            <Center>
                <Button
                    colorScheme='blue'
                    variant='outline'
                    size='sm'
                    leftIcon={<MdBuild />}
                >
                    Manage Data
                </Button>
            </Center>
        </Flex>
    )
}

export default ProjectItem
