import { ofType } from "redux-observable";
import { map } from 'rxjs/operators'
import fileDuck from './fileDuck'
import controlsDuck from './controlsDuck'
import projectDuck from './projectDuck'

import { createStandaloneToast } from "@chakra-ui/toast";

const notificationEpic = action$ => action$.pipe(
    // List actions that require Toast UI Feedback here:
    ofType(
        fileDuck.actions.fileError,
        fileDuck.actions.fileSuccess,
        projectDuck.actions.projectSuccess
    ),
    map((action$, state$) => {
        const { payload } = action$
        const toast = createStandaloneToast()

        toast({
            title: payload.status,
            description: payload.message,
            status: payload.status,
            duration: 5000,
            isClosable: true,
            position: 'top-right',
        })

        return controlsDuck.actions.activity(false)
    })


)

export default notificationEpic