import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, map, catchError } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax';
import authDuck from './authDuck'

import { auth } from '../api/auth'

const authEpic = action$ => action$.pipe(

    ofType(authDuck.actions.authenticate),
    mergeMap(action =>
        ajax.post(auth.login(), action.payload)
           .pipe(
                map(response => {
                    if (response.status === 201) return authDuck.actions.authSuccess(response.response)
                    if (response.status === 400) return authDuck.actions.authError('Login failed')
                }),
                catchError(error => of(authDuck.actions.authError(error))
            ),
        )
    ),
    catchError(error => of(authDuck.actions.authError(error)))
    )


export default authEpic