import React, { useState } from 'react';
// import { auth } from '../api/auth';
import Login from '../components/Login'
import { useDispatch, useSelector } from 'react-redux'
import authDuck from '../redux/authDuck' 
import { authError, isLoading } from '../redux/authSelector'

const LoginContainer = () => {
    const dispatch = useDispatch()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const error = useSelector(authError)
    const loading = useSelector(isLoading)


    const handleSubmit = async (event) => {
        event.preventDefault();
        dispatch(authDuck.actions.authenticate({email, password}))

    }

    return (
        <Login 
            handleSubmit={handleSubmit} 
            isLoading={loading} 
            error={error} 
            setEmail={setEmail} 
            setPassword={setPassword} 
        />
    )
}

export default LoginContainer