import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import uploadDuck from '../redux/uploadDuck'
import { userId } from '../redux/authSelector'

// Chakra
import {
    useToast,
    Progress
} from '@chakra-ui/react'

// Uppy
import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import { DragDrop } from '@uppy/react'
import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/progress-bar/dist/style.css'

const UploadContainer = () => {
    const dispatch = useDispatch()
    const user = useSelector(userId)
    const toast = useToast()
    const [progress, setProgress] = useState(0)

    const uppy = new Uppy({
        restrictions: { maxNumberOfFiles: 1 },
        autoProceed: true,
        debug: false,
    })

    uppy.use(AwsS3Multipart, {
        limit: 1,
        companionUrl: 'https://companion.c-core.app/',
    })

    const popToast = ({title, description, status}) => {
        toast({
            title: title,
            description: title,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top-right',
        })
    }

    uppy.on('file-added', file => {
        // Check authentication status before upload
        
        // onOpen()
        // TODO: UI feedback here to account for cold start time on companion container.
        popToast({
            title: 'Upload Started',
            description: 'Your upload is in progress',
            status: 'success'
        })
    })

    uppy.on('upload', file => {
        // popToast({
        //     title: 'Upload Started',
        //     description: 'Your upload is in progress...',
        //     status: 'success'
        // })
    })

    uppy.on('upload-success', (file, data) => {
        dispatch(uploadDuck.actions.uploadFile({...file, user}))
        popToast({
            title: 'Success',
            descriptoin: 'Your upload has completed',
            status: 'success'
        })
    })

    uppy.on('complete', props => {
        // TODO: Dispatch uploadDuck.actions.uploadFileSuccess(props)
    })

    uppy.on('progress', (progress) => {
        setProgress(progress)
        if (progress === 100) {setProgress(0)}
    })

    return (
        <>
            <DragDrop
                height='160px'
                uppy={uppy}
                locale={{
                    strings: {
                        // Text to show on the droppable area.
                        // `%{browse}` is replaced with a link that opens the system file selection dialog.
                        dropHereOr: 'Drop here or %{browse}',
                        // Used as the label for the link that opens the system file selection dialog.
                        browse: 'browse',
                    },
                }}
            />
            {progress > 0 && 
                <Progress value={progress} />
            }
        </>
    )
}

export default UploadContainer