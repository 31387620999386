
import { Flex, Box, CircularProgress, CircularProgressLabel } from "@chakra-ui/react"
import { useSelector } from 'react-redux'
import { storageUtilisation } from '../redux/fileSelector'

const UsageContainer = () => {
    const storageUsage = useSelector(storageUtilisation)

    // TODO: Logic to deal with MB / GB / TB amounts appropriately.
    return (                        
        <Flex>
            <Box>
                <CircularProgress
                    value={1}
                    color='blue.200'
                    thickness='12px'
                    size='100px'
                >
                    <CircularProgressLabel fontSize='xs'>
                        1%
                        <br />
                        {Math.round(storageUsage / 1000000)} MB
                    </CircularProgressLabel>
                </CircularProgress>
            </Box>
        </Flex>
    )
}

export default UsageContainer