// React / Redux
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, map, catchError } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax';


// C-CORE
import { getFiles } from '../api/files'
import fileDuck from './fileDuck'
import authDuck from './authDuck'

const fileEpic = action$ => action$.pipe(

  ofType(fileDuck.actions.getFiles, fileDuck.actions.fileSuccess),
  mergeMap((action, state$) =>
      // TODO: Remove the localStorage call and use Redux selector - Move as below
      // TODO: Use an axios style interceptor or global/instance default for headers.
      ajax({
        url: getFiles,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('rpas-token')}`
        }
      }).pipe(
        map(response => {
          if (response.status === 200) {
            return fileDuck.actions.getFileSuccess(response.response)
          }
          if (response.status === 401) {
            return authDuck.actions.logout()
          }
        }),
        catchError(error => console.log(error))
      )
  ),
  catchError(error => of(fileDuck.actions.getFileError(error)))
  )



export default fileEpic
