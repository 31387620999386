// React-y
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, map, catchError } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax';

// C-CORE
import fileDuck from './fileDuck'
import authDuck from './authDuck'
import { deleteFile } from '../api/files'

const notification = {
  status: 'error',
  message: 'File failed to delete'
}

const fileEpic = action$ => action$.pipe(

  ofType(fileDuck.actions.deleteFile),
  mergeMap((action$, state$) =>
      ajax({
        url: `${deleteFile}/${action$.payload}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('rpas-token')}`
        }
      })
      .pipe(
        map(xhr => {
          console.log('xhr: ', xhr)
          if (xhr.status === 204 || xhr.status === 200) {
            return fileDuck.actions.fileSuccess({status:'success', message: 'File deleted'})
          }
          // return fileDuck.actions.fileError({...notification, error: 'A Server error occurred, delete may have failed.'})
        }),
        catchError(error => {
          if (error.status === 401) {
            return of(authDuck.actions.logout({message: 'Your session has expired, please sign in again'}))
          }
          return of(fileDuck.actions.fileError({...notification, message: 'An application error occurred.', error}))
        })
      )
  ))

export default fileEpic