// React
// import { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'


// Chakra
import { Flex, Box, Image, Tabs, Tab, TabList, TabPanels, TabPanel } from '@chakra-ui/react'
import { getFiles } from '../redux/fileSelector'


// CCORE
import thumbnail from '../images/thumbnail_ph.png'
import KeywordContainer from '../containers/KeywordContainer';
import EditItemContainer from '../containers/EditItemContainer';
import DeleteFileBtnContainer from '../containers/DeleteFileBtnContainer'

const FileDetails = ({file_id}) => {
    
    const { id } = useParams();
    const files = useSelector(getFiles)
    const file = files.filter(file => file.id === id)[0]

    return (
        // TODO The two outer 'Box' components are common across all the router views for proper positioning and layout,
        // make them a container.
        <Box height='100%' overflow='hidden'>
        {/* Here down = scrollable box */}
            <Box
                height='calc(100vh - 75px)'
                maxH='calc(100vh - 75px)'
                overflow='scroll'
            >
                {file && 
                    <>
                    <Flex>
                        <Image ml="20px" mr="20px" boxSize="150px" src={thumbnail} />
                        <Box flexGrow={0.5} w={{base: 'calc(100%-20px)', md: 'calc(100%-20px)'}}>
                            <EditItemContainer name="file_label" file={file}/>
                            <EditItemContainer name="description" file={file} />
                            <KeywordContainer file={file} />
                            <DeleteFileBtnContainer file={file} />
                        </Box>
                    </Flex>
                    <Tabs m="20px" variant="soft-rounded">
                        <TabList>
                            <Tab>File information</Tab>
                            <Tab>Manage Sharing</Tab>
                            <Tab>File Access History</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                File Information
                            </TabPanel>
                            <TabPanel>
                                Manage users here...
                            </TabPanel>
                            <TabPanel>
                                File access history
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                    </>
                }
            </Box>
        </Box>
    )
}

export default FileDetails