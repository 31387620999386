
import React from 'react'
import ReactMapGL from 'react-map-gl'

const defaultMapboxApiAccessToken =
    'pk.eyJ1IjoiYy1jb3JlIiwiYSI6ImNqZWl3bzI0MzBsNm4zM21lcjBsZXpvajUifQ.gmSOoJC8y-fk9rNz88-gTg'
const defaultMapStyle = 'mapbox://styles/mapbox/satellite-v9'

function Map ({
    mapboxApiAccessToken = defaultMapboxApiAccessToken,
    width = '100%',
    height = '100%',
    mapStyle = defaultMapStyle,
    onViewportChange = console.log,
    viewport = {},
    mapOptions = {
        hash: false,
    },
    children,
}) {    return (
        <ReactMapGL
            {...viewport}
            width={width}
            height={height}
            mapboxApiAccessToken={mapboxApiAccessToken}
            mapStyle={mapStyle}
            onViewportChange={onViewportChange}
        >
            {children}
        </ReactMapGL>
    )
}

export default Map
