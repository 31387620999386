// Chakra
import {
    Box
} from '@chakra-ui/react'

// Appbase
import { DataSearch } from '@appbaseio/reactivesearch';

// React
import { useState, useEffect } from 'react'

const SearchContainer = () => {

    
    const [searchValue, setSearchValue] = useState('')
    
    const handleChange = (value, triggerQuery, event) => {
        setSearchValue(value)
        triggerQuery()
    }
    
    useEffect(() => {
        const interval = setInterval(() => {
            const old = searchValue
            setSearchValue('')
            setSearchValue(old)
            console.log('This will run every 5 seconds!');
        }, 5000);
        return () => clearInterval(interval);
    }, [searchValue])

    return (
        <Box
            ml={{ md: '190px' }}
            mt={{ md: '0px' }}
            width={{ base: 'auto', md: '500px' }}
        >
            <DataSearch
                // customQuery={geoQuery}
                dataField={[
                    'properties.title',
                    'properties.description',
                    'properties.keywords',
                    'properties.storage_key'
                ]}
                queryFormat="or"
                componentId="SearchSensor"
                filterLabel="Search"
                placeholder="Search Projects & Files"
                iconPosition="left"
                autosuggest={true}
                debounce={500}
                URLParams={true}
                className="es-form"
                enableRecentSearches={true}
                showClear={true}
                innerClass={{
                    input: 'form-search',
                }}
                onChange={handleChange}
                value={searchValue}
                // onKeyPress={handleKey}
                // onFocus={setTabToSearchResults} <- TODO: If this doesn't work, create custom search field.
            />
        </Box>
    )
}

export default SearchContainer
