// @flow

import React from 'react'
import { Source, Layer } from 'react-map-gl'

function RasterLayer ({
    id,
    tiles,
    item = undefined,
    bounds = undefined,
    type = 'raster',
    scheme = 'tms',
    minzoom = 0,
    maxzoom = 22,
    beforeId,
}: {
    id: string,
    tiles: [string],
    item?: any,
    bounds?: [],
    type?: string,
    scheme?: string,
    minzoom?: number,
    maxzoom?: number,
    beforeId?: string,
}) {
    const layer = { type: 'raster' }

    return (
        <>
            <Source
                id={id}
                type={type}
                bounds={bounds}
                tiles={tiles}
                minzoom={minzoom}
                maxzoom={maxzoom}
                scheme={scheme}
            >
                <Layer id={id} beforeId={beforeId} {...layer} />
            </Source>
        </>
    )
}

export default RasterLayer
