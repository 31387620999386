// Chakra
import {
    Box,
    Flex,
    Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels
} from '@chakra-ui/react'

// React
import { useDispatch, useSelector } from 'react-redux'
import UsageContainer from '../containers/UsageContainer'
import controlsDuck from '../redux/controlsDuck'
import { selectedTab } from '../redux/controlsSelector'

import UploadContainer from '../containers/UploadContainer'
import ListProjectsContainer from '../containers/ListProjectsContainer'
import SearchResultsContainer from '../containers/SearchResultsContainer'

const Home = () => {
    const dispatch = useDispatch()
    const tab = useSelector(selectedTab)

    const handleTabChange = (newTabIndex) => {
        dispatch(controlsDuck.actions.tabIndex(newTabIndex))
    }

    return (
        <Box height='100%' overflow='hidden'>
            {/* Here down = scrollable box */}
            <Box
                height='calc(100vh - 75px)'
                maxH='calc(100vh - 75px)'
                overflow='scroll'
            >
                <Tabs variant="line" index={tab} onChange={handleTabChange}>
                    <TabList ml={2} variant="">
                        <Tab >Files</Tab>
                        <Tab >Upload</Tab>
                        <Tab isDisabled>Projects</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <SearchResultsContainer />
                        </TabPanel>
                        <TabPanel>
                            <Flex m='4'>
                                <Box width='66%'>
                                    <UsageContainer />
                                </Box>
                                <Box width='33%'>
                                    <UploadContainer />
                                </Box>
                            </Flex>
                        </TabPanel>
                        <TabPanel>
                            <ListProjectsContainer />
                        </TabPanel>
                    </TabPanels>
                </Tabs>                
            </Box>
        </Box>
    )
}

export default Home
