export const baseUri = process.env.REACT_APP_API

// ES
export const es_key = process.env.REACT_APP_ES_KEY
export const es_index= process.env.REACT_APP_ES_INDEX
export const es_type = process.env.REACT_APP_ES_TYPE
export const es_url = process.env.REACT_APP_ES_URL

// Mapbox
export const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
export const basemapStyle = 'mapbox://styles/mapbox/satellite-v9'
export const defaultViewport = {
    latitude: 55.6434,
    longitude: -4.17,
    zoom: 12,
    width: 250,
    height: 250
}