import {
    Avatar,
    Center,
    MenuItem,
    MenuList,
    Menu,
    MenuButton,
} from '@chakra-ui/react'

import authDuck from '../redux/authDuck'
import { useDispatch } from 'react-redux'
// TODO: THis should be in a container...

/**
 * Component for interacting with user's identity
 */
const Identity = ({ height = '65px', p = '4', name = null, src = null }) => {
    
    const dispatch = useDispatch()
    const handleLogout = async () => {
        dispatch(authDuck.actions.logout({message: 'You have been logged out'}))    
    }

    return (
        <>
            <Center
                w='75px'
                h={height}
                // mt="10px"
                display={{ base: 'none', md: 'inherit' }}
            >
                <Menu>
                    <MenuButton>
                        <Avatar />
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </MenuList>
                </Menu>
            </Center>
        </>
    )
}

export default Identity
