import { createSlice } from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'


const token = localStorage.getItem('rpas-token')
const user_id = token ? jwt_decode(token).user_id : ''

const initialState = {
    loading: false,
    token: localStorage.getItem('rpas-token'),
    email: '',
    user_id: user_id,
    error: false,
    errorMessage: null,
}

function authenticate (state) {
    return {
        ...state,
        loading: true
    }
}

function authSuccess (state, action) {
    const { payload } = action
    localStorage.setItem("rpas-token", payload.token)
    const decodedToken = jwt_decode(payload.token)
    const user_id = decodedToken.user_id
    return { 
        ...state,  
        token: payload.token,
        user_id: user_id,
        error: false,
        errorMessage: '',
        loading: false,
    }
}

function authError (state, action) {
    const { payload } = action
    return { 
        ...state,  
        rpasToken: '',
        email: '',
        user_id: '',
        error: true,
        loading: false,
        errorMessage: 'Incorrect username or password',
        payload
    }
}

// TODO: Epic middleware to dispatch request to 'api/v1/auth/logout' 
function logout (state, action) {
    localStorage.removeItem("rpas-token")
    const { payload } = action
    return {
        ...state,
        token: '',
        email: '',
        errorMessage: payload.message
    }
}

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authenticate,
        authSuccess,
        authError,
        logout,
    },
})

export default auth