import { useState } from 'react'
import { Heading, Flex, Wrap, WrapItem, Badge } from '@chakra-ui/react'
import { Input, InputGroup, InputRightElement, IconButton, Spacer, Box } from '@chakra-ui/react'
import { CheckIcon, EditIcon, CloseIcon } from '@chakra-ui/icons'
import { Fragment } from 'react'

const ShowKeywords = ({content}) => {
    return (
        <Wrap spacing="10px">
            {content.map((kw, i) => <WrapItem key={i}><Badge colorScheme='blue'>{kw}</Badge></WrapItem>)}
        </Wrap>
    )
}

const EditKeywords = ({content, handleChange, close}) => {
    const [value, setValue] = useState(content)

    const handleEdit = (event) => {    
        handleChange(value)
        close()
    }

    const handleUndo = () => {
        setValue(content)
        close()
    }

    return (
        <Fragment>
        <InputGroup >
            <Input value={value} onChange={(event)=>setValue(event.target.value)} />
            <InputRightElement>
                <IconButton onClick={handleEdit} size="sm" variant="ghost" icon={<CheckIcon color="green.500" />}></IconButton>
            </InputRightElement>
        </InputGroup>
        <IconButton onClick={handleUndo} size="xs" variant="ghost" icon={<CloseIcon color="gray.500" />} mt={2}/>
        </Fragment>
    )
}

const Keyword = ({content, handleSave}) => {
    const [display, setDisplay] = useState(content)
    const [edit, setEdit] = useState(false)
    const [visible, setVisible] = useState(false)
    const handleEdit = (value) => {
        handleSave(value)
        setDisplay(value.split(","))
    }

    return (
        <>
            <Heading fontSize='xs'>Keywords:</Heading>
            <Flex pt='4px' onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)} width="100%">
                {edit
                    ?   <EditKeywords content={display} handleChange={handleEdit} close={()=>setEdit(false)}/>   
                    :   <Flex width="100%" align="center">
                            <Box>
                                <ShowKeywords content={display} value={content}/>
                            </Box>
                                <Spacer />
                            <Box>
                                <IconButton visibility={visible ? "visible" :"hidden"} mb={""} onClick={() => setEdit(true)} size="sm" variant="ghost" icon={<EditIcon />}></IconButton>
                            </Box>
                        </Flex>
                }
            </Flex>
        </>
    )
}

export default Keyword