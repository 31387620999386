// React
import Header from '../components/Header'
import Logo from '../components/Logo'
import Identity from '../components/Identity'
import MobileHeader from '../components/MobileHeader'
import MobileMenu from '../components/MobileMenu'
import SearchContainer from './SearchContainer'

// Chakra
import { useDisclosure, Spacer } from '@chakra-ui/react'

const HeaderContainer = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    /**
     * TODO: Parent menu items and submenus:
     * @param [{parentTitle: '', menuItems: []}, ...]
     *
     */
    const menuItems = []
    return (
        <Header menuItems={menuItems}>
            <Logo />
            <SearchContainer />
            <Spacer />
            <Identity />
            <MobileHeader onOpen={onOpen} title='Mobile Menu Title' />
            <MobileMenu
                onClose={onClose}
                isOpen={isOpen}
                menuItems={menuItems}
            />
        </Header>
    )
}

export default HeaderContainer
